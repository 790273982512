// import node_modules
import React from 'react';

// import React components
import Layout from '../components/Layout';

// Declare React class
const IndexPage = () => (
  <Layout
    canonical="https://www.echogramm.de"
    description="Das Echogramm liefert dir die wichtigsten News deiner Branche auf einen Blick. Jetzt testen."
    image="https://www.echogramm.de/img/echogramm-bubbles.jpg"
    lang="de"
    title="Echogramm | Frischer Wind in deiner Filterblase"
  >
    <section className="hero hero-section is-medium bg-image-hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-mobile is-centered">
            <div className="column is-full-mobile is-full-tablet is-full-desktop has-text-centered has-padding-top-3">
              <h1 className="title is-size-1 has-text-white">
                Frischer Wind in deiner Filter&shy;blase
              </h1>
              <p className="is-size-4 has-text-white">
                Das Echogramm liefert dir die wichtigsten News deiner Branche auf einen Blick.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about" className="section">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered">
            <p className="is-size-5">
              Das Echogramm ist eine Social News App für Unternehmen. Die App aggregiert und
              kuratiert die besten und wichtigsten Inhalte, aktuellsten News und neuesten Trends
              deiner Branche. Profitiere von tollen Features, um die Informationsflut zu bewältigen
              und den Anschluss in der sich stetig wandelnden digitalen Welt nicht zu verpassen.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section bg-image-features">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered">
            <h2 className="is-size-3 has-text-white">So funktio&shy;niert das Echo&shy;gramm</h2>
          </div>
        </div>
        <div className="columns is-mobile is-centered is-multiline">
          <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop has-padding-left-1 has-padding-right-1">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <span className="icon is-large is-echogramm">
                      <svg
                        fill="white"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM8 7h8v2H8V7zm0 4h8v2H8v-2zm0 4h8v2H8v-2z" />
                      </svg>
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Einfach alles im Blick</p>
                    <p className="subtitle is-6">Alle Branchen News in einer App</p>
                  </div>
                </div>
                <div className="content">
                  Das Echogramm aggregiert und kuratiert alle relevanten News aus deiner Branche in
                  einer App. Die Inhalte werden kategorisiert, so dass du sie filtern und sortieren
                  kannst. Es werden zudem nur Inhalte aus qualifizierten Quellen verwendet.
                </div>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop has-padding-left-1 has-padding-right-1">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <span className="icon is-large is-echogramm">
                      <svg
                        fill="white"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1zm13 2H6v15.432l6-3.761 6 3.761V4z" />
                      </svg>
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Effektiver konsu&shy;mieren</p>
                    <p className="subtitle is-6">News&shy;letter und Später&shy;lesen</p>
                  </div>
                </div>
                <div className="content">
                  Damit du keine Meldung mehr verpasst, kannst du dir die Inhalte zum Späterlesen
                  via Pocket oder Buffer speichern. Weiter erhältst du und dein Team einmal
                  wöchentlich eine Zusammenfassung der wichtigsten Themen deiner Branche per Mail.
                </div>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop has-padding-left-1 has-padding-right-1">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <span className="icon is-large is-echogramm">
                      <svg
                        fill="white"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 15.968l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275v10.693zm0 2.292l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26z" />
                      </svg>
                    </span>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Nach Relevanz sortiert</p>
                    <p className="subtitle is-6">Die meistgeteilten Inhalte finden</p>
                  </div>
                </div>
                <div className="content">
                  Zu jedem Inhalt liefert dir das Echogramm einen Social Score aus. Dieser zeigt
                  dir, welcher Inhalt wie oft geteilt, kommentiert und geliked wurde. Dadurch
                  findest du die heiß&shy;diskutierten Artikeln nach Relevanz sortiert.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section is-paddingless">
      <div className="bubbles-section-wrapper">
        <div className="bubbles-content">
          <div className="container has-padding-top-5 has-padding-bottom-5">
            <div className="columns is-mobile is-centered">
              <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered has-padding-left-2 has-padding-right-2">
                <h2 className="is-size-3">Dein Branchen-Echogramm</h2>
                <p className="is-size-5">
                  Das Echogramm kann für jede beliebige Branche abonniert werden. Dafür werden die
                  wichtigsten deutschsprachigen Kanäle und Medien ausgewertet und als kompakter
                  Newsfeed ausgegeben. Es ist möglich das Echogramm komplett auf dein Marken-Design
                  anzupassen.
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop">
                <div className="topic-box-container">
                  <div className="topic-box automotive">
                    <h3 className="topic-box-text">Auto&shy;motive</h3>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop">
                <div className="topic-box-container">
                  <div className="topic-box architektur">
                    <h3 className="topic-box-text">Archi&shy;tektur</h3>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop">
                <div className="topic-box-container">
                  <div className="topic-box sport">
                    <h3 className="topic-box-text">Sport</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop">
                <div className="topic-box-container">
                  <div className="topic-box maschinenbau">
                    <h3 className="topic-box-text">Maschinen&shy;bau</h3>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop">
                <div className="topic-box-container">
                  <div className="topic-box fashion">
                    <h3 className="topic-box-text">Fashion</h3>
                  </div>
                </div>
              </div>
              <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop">
                <div className="topic-box-container">
                  <div className="topic-box smart-home">
                    <h3 className="topic-box-text">Smart Home</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="bubbles-background">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
      </div>
    </section>

    <section className="section bg-image-process">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered">
            <h2 className="is-size-3">Du willst keine News mehr verpassen?</h2>
            <p className="is-size-5">
              In drei erprobten Schritten bekommst du dein eigenes Branchen-Echogramm.
            </p>
          </div>
        </div>
        <div className="columns is-mobile is-centered is-multiline">
          <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop has-padding-left-1 has-padding-right-1">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <h3 className="title is-4">1. Re&shy;cherche</h3>
                  </div>
                </div>
                <div className="content">
                  Nach der Bestellung deines Echogramms kuratiert unser Redaktionsteam die
                  relevantesten deutschsprachigen Kanäle und Medien deiner Branche.
                </div>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop has-padding-left-1 has-padding-right-1">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <h3 className="title is-4">2. Implemen&shy;tierung</h3>
                  </div>
                </div>
                <div className="content">
                  Nun wird dein persönliches Echogramm erstellt. Die selektierten Kanäle werden
                  eingebunden und die App für dich eingerichtet. Auf Wunsch hin personalisieren wir
                  das Design der App für dein Unternehmen.
                </div>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-four-fifths-tablet is-one-third-desktop has-padding-left-1 has-padding-right-1">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <h3 className="title is-4">3. Veröffent&shy;lichung</h3>
                  </div>
                </div>
                <div className="content">
                  Los geht&apos;s. Die News App ist eingerichtet – von nun an erhältst du
                  regelmäßige Updates, Newsletter und Auswertungen zu allem, was Deine Branche
                  bewegt.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="columns is-mobile is-centered is-vcentered is-multiline">
          <div className="column is-full-mobile is-full-tablet is-half-desktop is-two-fifths-widescreen is-one-third-hd has-padding-left-2 has-padding-right-2 has-padding-bottom-2">
            <figure className="image is-square">
              <img
                src="/img/marketing-und-medien-echogramm.png"
                alt="Das Marketing und Medien-Echogramm"
                title="Das Marketing und Medien-Echogramm"
              />
            </figure>
          </div>
          <div className="column is-full-mobile is-full-tablet is-half-desktop is-two-fifths-widescreen is-one-third-hd has-text-centered-mobile">
            <h3 className="is-size-3">Das Marketing- und Medien-Echogramm</h3>
            <p className="is-size-5">
              Melde dich bei uns und lass dir anhand des Marketing- und Medien-Echogramms zeigen,
              wie du effektiver Deine Branchen-Inhalte verwalten, filtern, sortieren und lesen
              kannst. Oder überzeuge dich selbst, wie du mit dem Echogramm Up-to-date bleibst und
              keine wichtige Meldung mehr verpasst.
            </p>
            {/* <p>
              <a
                href="https://media.echogramm.de"
                target="_blank"
                rel="noopener noreferrer"
              >
                Marketing- und Medien-Echogramm testen &rarr;
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </section>

    <section id="demo" className="section bg-demo">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered">
            <h2 className="is-size-2">Demo anfordern</h2>
            <p className="is-size-5">
              Das Echogramm ist eine Social News App, die dir alle wichtigsten News deiner Branche
              auf einen Blick liefert. In einem persönlichen Gespräch zeigen wir dir die Funktionen
              und Möglichkeiten des Echogramms für dein Unternehmen. Du möchtest frischen Wind in
              deiner und der Filterblase deines Teams? Fordere jetzt unverbindlich eine persönliche
              Produktvorführung an.
            </p>
            <p>
              <a
                href="mailto:echogramm@mii.ventures?subject=Produktdemo Echogramm"
                className="button is-echogramm"
              >
                Produktdemo anfordern
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
